import Vue from 'vue';
import router from './router';

import App from './App.vue';
import Scanner from './components/Scanner.vue';
import Response from './components/Response.vue';
import History from './components/History.vue';

Vue.component('scanner', Scanner);
Vue.component('response', Response);
Vue.component('history', History);

window.axios = require('axios');

new Vue({
    router,
    render: function (h) { return h(App) }
}).$mount('#app');
