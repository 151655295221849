<template>
  <div class="history">
    <h1>History</h1>
    <p>Total : {{ totalNum }}</p>
    <p class="valid">Valid : {{ validNum }}</p>
    <p class="invalid">Invalid : {{ invalidNum }}</p>
    <p class="expired">Error : {{ expiredNum }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalNum: 0,
      validNum: 0,
      invalidNum: 0,
      expiredNum: 0,
    };
  },
  methods: {
    ticketValid() {
      this.totalNum++;
      this.validNum++;
    },
    ticketInValid() {
      this.totalNum++;
      this.invalidNum++;
    },
    ticketExpired(){
      this.totalNum++;
      this.expiredNum++;
    }
  },
};
</script>

<style scoped>
.history {
  position: fixed;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: black;
  background-color: rgb(255, 255, 255);
  padding: 5px 25px;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 2px 10px rgb(0 0 0 / 25%);
  min-height: 275px;
  min-width: 150px;
}

.valid{
  color:black;
  background-color: rgb(81, 255, 0);
  border-radius: 10px;
}

.invalid{
  color:black;-color:orange;
  background-color:orange;
  border-radius: 10px;
}

.expired{
  color:black;-color:orange;
  background-color: red;
  border-radius: 10px;
}

h1{
  font-family: 'DMSans-Bold';
}

p{
  font-family: 'DMSans-Regular';
  padding: 4px;
}

@font-face {
	font-family: 'DMSans-Bold';
	src: url('DMSans-Bold.ttf'); 
}

@font-face {
	font-family: 'DMSans-Regular';
	src: url('DMSans-Regular.ttf'); 
}
</style>