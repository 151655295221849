<template>
  <body>
    <div v-if="authentication == null">
      <span class="error">Invalid URL.</span>
    </div>
    <div v-else-if="authentication == 'error'">
      <span class="error">Invalid token.</span>
    </div>
    <div class="container" v-else>
      <history class="history" v-on:test="test" ref="history" />
      <scanner
        :authentication="authentication"
        v-on:ticketvalid="ticketvalid"
        v-on:ticketinvalid="ticketinvalid"
        v-on:ticketexpired="ticketexpired"
        class="scanner"
      />
    </div>
  </body>
</template>

<script>
export default {
  data() {
    return {
      authentication: null,
    };
  },

  mounted() {
    try {
      let url = new URL(window.location.href);
      let code = url.searchParams.get("token");
      if (code == null) {
        this.authentication = null;
      } else {
        this.authentication = JSON.parse(
          atob(code)
        );
        let keys = Object.keys(this.authentication);

        if (keys.length != 2) {
          this.authentication = "error";
        }

        for (let k of keys) {
          if (!["prefix", "token"].includes(k)) {
            this.authentication = "error";
          }
        }
      }
    } catch (e) {
      this.authentication = "error";
    }
  },
  methods: {
    test() {
      console.log("event received");
      console.log("test");
    },
    ticketexpired() {
      this.$refs.history.ticketExpired();
    },

    ticketvalid() {
      this.$refs.history.ticketValid();
    },

    ticketinvalid() {
      this.$refs.history.ticketInValid();
    },
  },
};
</script>

<style>
body {
  background-color: #f5f6fa;
}

.error {
  color: red;
  font-weight: bold;
}
</style>