<template>
    <div v-if="response.status == 'ERROR'">
        <p>{{ response.response }}</p>
    </div>
    <div v-else>
        <p class="mb-1">{{ response.info }}</p>
        
        <p class="header mb-1">Customer <span>{{ response.details.customer.name.full }} - {{ response.details.customer.email }} - {{ response.details.customer.phone }}</span></p>
    
        <table>
            <tr>
                <th></th>
                <th></th>
            </tr>
            <tr>
                <td class="header">Ticket</td>
                <td>{{ response.details.ticket.product.title }}</td>
            </tr>
            <tr v-if="response.details.ticket.plot">
                <td class="header">Visit date</td>
                <td>{{ response.details.ticket.plot.date }}</td>
            </tr>
            <tr v-if="response.details.ticket.plot && response.details.ticket.plot.time">
                <td class="header">Visit timeslot</td>
                <td>{{ response.details.ticket.plot.time.start }} - {{ response.details.ticket.plot.time.end }}</td>
            </tr>
            <tr v-if="response.details.ticket.audience">
                <td class="header">Ticket audience</td>
                <td>{{ response.details.ticket.audience.name }}</td>
            </tr>
        </table>
    </div>
    
</template>

<script>
export default {
    props: ['response']
}
</script>

<style scoped>
p {
    margin: 0px;
    padding: 0px;
}

.header {
    font-weight: bold;
    font-size: 20px;
}

td.header {
    padding-right: 5px;
}

p.header > span {
    font-weight: 100;
    font-size: 16px;
}

.mb-1 {
    margin-bottom: 1em;
}
</style>