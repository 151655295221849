<template>
  <div>
    <div>
      <div class="scanner" :class="background" v-if="focus">
        <h1 class="center-h1">Booqi scanner</h1>
        <p v-if="response != null">
          <response :response="response" />
        </p>
        <p v-else-if="loading == false && barcode != ''">scanning barcode..</p>
        <p v-else-if="loading">ticket check-in..</p>
        <p v-else-if="barcode == ''">scanner is awaiting input..</p>
      </div>
      <div class="scanner center" :class="background" v-else>
        <p>Please make sure the site is in focus!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["authentication"],

  data() {
    return {
      barcode: "",
      timer: null,
      lastKey: Date.now(),
      loading: false,
      response: null,
      focus: false,
    };
  },

  mounted() {
    this.focus = window.document.hasFocus();

    document.addEventListener("keydown", this.input);
    document.addEventListener("click", this.reset);

    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.unfocus);

    const self = this;
    this.timer = window.setInterval(function () {
      if (self.barcode != "" && Date.now() - self.lastKey > 750) {
        self.barcode = "";
      }
    }, 500);
  },

  destroyed() {
    document.removeEventListener("keydown", this.input);
    document.removeEventListener("click", this.reset);

    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.unfocus);
    clearInterval(this.timer);
  },

  methods: {
    input(e) {
      this.response = null;
      this.loading = false;
      this.lastKey = Date.now();

      if (e.key == "Enter") {
        this.submit();
      } else if (/^[a-zA-Z0-9 ]{1}$/.test(e.key)) {
        this.barcode += e.key;
      }
    },

    submit() {
      if (this.barcode == "") {
        return;
      }
      this.loading = true;

      axios
        .post("https://scanner.booqi.app/api.php", {
          auth: this.authentication,
          barcode: this.barcode,
        })
        .then((res) => {
          this.loading = false;

          if (res.data.ok) {
			
			if (res.data.status == 'SUCCESS') {
				this.$emit("ticketvalid");
			} else if (res.data.status == 'WARNING') {
				this.$emit("ticketinvalid");
			}

            this.response = {
              ...res.data.response,
              status: res.data.status,
            };
          } else {
            this.$emit("ticketexpired");
            this.response = {
              response: res.data.error_info,
              status: res.data.status,
            };
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    reset() {
      this.barcode = "";
      this.response = null;
      this.loading = false;
    },

    onFocus() {
      this.focus = true;
    },

    unfocus() {
      this.focus = false;
    },
  },

  computed: {
    background() {
      if (this.response == null || this.response == undefined) return "waiting";
      return this.response.status.toLowerCase();
    },
  },
};
</script>

<style scoped>
.scanner {
  position: fixed;
  top: 50%;
  left: 42%;
  transform: translate(-9%, -50%);
  color: black;
  padding: 5px 25px;
  border-radius: 10px;
  display: inline-block;
  min-height: 275px;
  min-width: 275px;
  max-width: 275px;
  text-align: center;
  vertical-align: middle;
}
.center {
  line-height: 275px;
}
.center-h1 {
  font-size: 39px;
  line-height: 200px;
}
h1,
p {
  padding: 2px;
  margin: 0px;
}

p {
  font-weight: 100;
}

.waiting {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 10px rgb(0 0 0 / 25%);
}

.warning {
  background-color: rgb(255, 204, 37);
}

.error {
  background-color: red;
}

.success {
  background-color: lime;
}

h1,
h3 {
  font-family: "DMSans-Bold";
}

p {
  font-family: "DMSans-Regular";
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("DMSans-Bold.ttf");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("DMSans-Regular.ttf");
}
</style>